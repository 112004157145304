<template>
  <div class="home">
    <img
      class="img_not_found"
      width="224.15"
      height="319.62"
      src="@/assets/images/logo/il_erro_404.svg"
    >
    <p class="message_not_found">
      OOPS! Ocorreu um erro inesperado tente novamente mais tarde.
    </p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
}
</script>
<style>
.home {
  text-align: center;
  /*max-width: 400px*/
}
.img_not_found{
  margin-bottom: 50px;
}
.message_not_found{
  margin-top: 20px;
  color: #999999;
  font-size: 18px;
  font-family: 'Roboto:400', sans-serif;
  max-width: 224.15px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
</style>
